import {createPinia} from 'pinia';
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import lazy from './directives/lazy'
import resize from './directives/resize'
import plugins from "@/plugins";

const app = createApp(App);
app.use(createPinia());
app.use(lazy);
app.use(plugins);
app.use(resize);
app.use(router);
app.mount('#app')
