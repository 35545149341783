import STitle from "@/components/services/STitle.vue";
import UStar from "@/components/UStar.vue";

const components = [
    STitle,
    UStar,
];

export default {
    install(app) {
        components.forEach((component) => {
            app.component(component.name, component);
        });
    },
};

