import {defineStore} from 'pinia';
import {paperServicePackages, paperServices} from "@/api/paper";
import {toast} from "@/common/utils";

const guest = {
    nickname: '',
    username: '',
    mid: 0,
    usertype: 0,
    token: ''
}

export const useUserStore = defineStore('user', {
    state: () => {
        return {
            userInfo: guest,
            isLogin: false,
            services: [],
            servicePackages: [],
        }
    },
    actions: {
        async fetch() {
            let userInfo = localStorage.getItem('user_info');
            if (userInfo) {
                userInfo = Object.assign(guest, JSON.parse(userInfo));
            }
            if (userInfo) {
                this.isLogin = !!(userInfo.token && userInfo.token.length > 0 && userInfo.username);
            }
        },
        logout() {
            localStorage.removeItem('user_info');
            this.isLogin = false;
        },
        async getServices(pid){
            const res = await paperServices({
                token: this.userInfo.token,
                pid
            });
            if (res.code !== 1) {
                return false;
            }
            this.services = res.data;
        },
        async getServicePackages(serviceId){
            const res = await paperServicePackages({
                token: this.userInfo.token,
                service_pid: serviceId
            });
            if (res.code !== 1) {
                return false;
            }
            this.servicePackages = res.data;
        }
    }
})
