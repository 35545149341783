import {createRouter, createWebHistory} from 'vue-router'

import {basicRoutes} from "@/router/routes";


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: basicRoutes,
    scrollBehavior: () => ({left: 0, top: 0}),
})
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next();
});

router.afterEach(() => {
    window.scrollTo(0, 0)
});

export default router
