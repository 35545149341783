import defaultImg from '../assets/loading.gif';

const lazy = {
    /**
     * 要在 Mounted 时期
     * @param el 指定元素对象
     * @param binding 指令的值
     */
    mounted(el, binding) {
        const observer = new IntersectionObserver(([{isIntersecting}]) => {
            if (isIntersecting) {
                observer.unobserve(el);
                el.src = binding.value;
                el.onerror = () => {
                    el.src = defaultImg;
                };
            }
        });
        observer.observe(el);
    },
};

export default function directiveLazy(app) {
    app.directive('lazy', lazy);
}
