import {getToken} from "@/common/utils";

export function post(url, data, method) {
    return new Promise((resolve, reject) => {
        reject = reject || function () {
        }
        resolve = resolve || function () {
        }
        data = data || {};
        if (!url) {
            reject({
                'msg': 'url 不能为空'
            });
            return false;
        }
        if (!data) {
            reject({
                'msg': 'data 不能为空'
            });
            return false;
        }
        const xhr = new XMLHttpRequest();
        xhr.open(method || 'POST', url);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=utf-8');
        if (document.querySelector('meta[name="csrf-token"]')) {
            xhr.setRequestHeader('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').getAttribute('content'));
        }
        if (window.localStorage.getItem('login_token')) {
            xhr.setRequestHeader('Authorization', getToken());
        }
        // if (config.withCredentials) xhr.withCredentials = true;
        xhr.ontimeout = xhr.onerror = xhr.onabort = function (e) {
            reject({status: 500, msg: {timeout: '请求超时', error: '无法连接网络', abort: '请求中止'}[e.type], type: e.type});
        };
        xhr.onreadystatechange = function () {
            let resText = xhr.responseText;
            if (xhr.readyState === 4 && xhr.status === 200) {
                try {
                    let res = JSON.parse(resText);
                    resolve(res);
                } catch (e) {

                    reject(resText);
                }
            }
        };
        xhr.send(JSON.stringify(data));
    });
}

