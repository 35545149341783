<script setup>
import {defineProps} from 'vue';

defineProps({
    text: {
        type: String,
        default: '',
    },
    barWidth: {
        type: Number,
        default: 0
    },
    barColor: {
        type: String,
        default: '',
    }

})
</script>
<script>
export default {
    name: 'STitle'
}
</script>
<template>
    <div class="title" :class="`bar-${barWidth}`">
        <span>{{ text }}</span>
        <i class="bar" :style="{backgroundColor: barColor? barColor: '#32BEFF'}"></i>
    </div>
</template>

<style lang="scss" scoped>
.title {
    color: #000;
    font-size: 32px;
    line-height: 1;
    font-weight: bold;
    margin-bottom: 36px;
    position: relative;
    text-align: center;

    span {
        position: relative;
        z-index: 2;
    }

    .sub-title {
        color: #444;
        font-size: 16px;
    }

    .bar {
        display: block;
        position: absolute;
        left: 50%;
        margin-left: -50px;
        width: 100px;
        height: 14px;
        bottom: -7px;
        z-index: 1;
    }

    &.bar-200 {
        &:after {
            width: 200px;
            margin-left: -100px;
        }
    }
}
</style>
