import {BAIDU_MAP_AK} from "@/common/config";

export function baiduMap() {
    return new Promise(function (resolve, reject) {
        window.baiduMap = function () {
            // eslint-disable-next-line no-undef
            var map = new BMapGL.Map("map");
            // eslint-disable-next-line no-undef
            map.centerAndZoom(new BMapGL.Point(116.404, 39.915), 12);
            map.enableScrollWheelZoom(true);
        }
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = `https://api.map.baidu.com/api?type=webgl&v=1.0&&ak=${BAIDU_MAP_AK}&callback=baiduMap`
        script.onerror = reject
        document.head.appendChild(script)
    })
}

export function encodeQuery(data) {
    const ss = [];
    for (let k in data) {
        let v = data[k];
        if (v == null) continue;
        if (typeof v === "object") v = JSON.stringify(v);
        ss.push(encodeURI(k) + '=' + encodeURI(v));
    }
    return ss.join('&');
}

export function decodeQuery(str) {
    const data = {};
    const ss = str.split('&');
    for (let i = 0; i < ss.length; i++) {
        let s = ss[i].split('=');
        if (s.length !== 2) continue;
        let k = decodeURIComponent(s[0]);
        let v = decodeURIComponent(s[1]);
        if (/^\[{/.test(v)) try {
            v = JSON.parse(v);
        } catch (e) {
            console.log(e);
        }
        data[k] = v;
    }
    return data;
}

export const getToken = () => 'Bearer ' + window.localStorage.getItem('login_token');

export const awaitWrap = (promise) => promise.then(function () {
    return [null, ...arguments];
}).catch(err => {
    return [err, null];
});

export const isValidPhoneNumber = str => RegExp(/^1[3-9][0-9]{9}$/).test(str)
export const isValidEmail = str => RegExp(/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/).test(str);
export const isValidSmsCode = str => str.length === 4;
export const isValidUsername = str => RegExp(/^[a-zA-z]\w{3,15}$/).test(str);

function css(eleName, item) {
    const eleItems = document.querySelectorAll(eleName);
    if (eleItems && eleItems.length < 1) return false;
    for (let eleItemsIndex = 0; eleItemsIndex < eleItems.length; eleItemsIndex++) {
        let ele = eleItems[eleItemsIndex];
        if (item instanceof Object) {
            for (let i in item) {
                ele.style[i] = item[i];
            }
        }
    }
}

export const toast = (msg, ele, msgType, opt) => {
    const toastEle = document.createElement('div');
    const toastText = document.createElement('span');
    toastText.className = 'toast-text';
    msgType = msgType || '';
    toastEle.className = 'toast ' + msgType || '';
    toastText.innerHTML = msg;
    toastEle.appendChild(toastText)
    opt = opt || {}
    if (ele) {
        if (typeof ele === "string") {
            ele = document.querySelector(ele);
            if (!ele) {
                return false;
            }
        }
        console.log(ele)
        ele.appendChild(toastEle);
    } else {
        document.body.appendChild(toastEle);
    }
    css('.toast', {
        display: 'flex',
        position: 'absolute',
        left: '0',
        right: '0',
        top: opt.top ? opt.top : '10%',
        zIndex: '99999999',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        transform: 'translateY(-50%)',
        color: '#fff',
        userSelect: 'none',
        boxSizing: 'border-box',
        transition: 'opacity .3s, transform .4s, top .4s',
    });
    css('.toast-text', {
        padding: '.5em 1em',
        borderRadius: '5px',
        fontSize: '16px',
        border: '1px solid #ebeef5',
        boxShadow: '0 0 10px rgba(0, 0, 0, .25)',
        transition: 'opacity .3s, transform .4s, top .4s',
    });
    switch (msgType) {
        case 'success':
            css('.toast-text', {
                backgroundColor: '#f0f9eb',
                borderColor: '#e1f3d8',
                color: '#67c23a',
            });
            break
        case 'warning':
            css('.toast-text', {
                backgroundColor: '#fdf6ec',
                borderColor: '#faecd8',
                color: '#e6a23c',
            });
            break;
        case 'error':
            css('.toast-text', {
                backgroundColor: '#fef0f0',
                borderColor: '#fde2e2',
                color: '#f56c6c',
            });
            break;
        default:
            css('.toast-text', {
                backgroundColor: 'rgba(0, 0, 0, .95)',
                borderColor: 'transparent',
                color: '#fff',
            });
    }
    setTimeout(function () {
        if (toastEle) {
            if (ele) {
                ele.removeChild(toastEle);
            } else {
                document.body.removeChild(toastEle);
            }
        }
    }, 5000);
}

export const convertOrderStatusText = (status) => {
    let statusText = '待付款';
    if (status === 1) {
        statusText = '待付款';
    }
    if (status === 2) {
        statusText = '待处理';
    }
    if (status === 3) {
        statusText = '处理中';
    }
    if (status === 4) {
        statusText = '已完成';
    }
    return statusText;
}
