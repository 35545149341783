<script setup>
import {ref, computed, onMounted} from 'vue'
import {useUserStore} from "@/store/user";
import {useRouter} from 'vue-router'
import {isValidPhoneNumber} from "@/common/utils";

const userStore = useUserStore();
const router = useRouter();

const aboutNav = ref([
    {name: "公司简介", path: "/about"},
    {name: "团队简介", path: "/about/team"},
    {name: "客户评价", path: "/about/comment"},
    {name: "润色案例", path: "/about/project"},
    {name: "联系我们", path: "/about/contact"},
]);
const servicesIntroNav = ref([
    {
        name: '英文润色',
        path: '/english_editing',
    },
    {
        name: '学术翻译',
        path: '/translating',
    },
    {
        name: '语言改写降重',
        path: '/paper_repeat'
    },
    {
        name: '论文查重',
        path: '/edit_repeat'
    },
    {
        name: '英文查重',
        path: '/english_repeat'
    },
]);

function doLogout() {
    userStore.logout();
}

function toModifyPassword() {
    router.push({
        name: 'modify_password',
    });
}

function toUserCenter() {
    router.push({
        name: 'upload_paper',
    });
}

onMounted(async () => {
    userStore.fetch();
});

function toInQueryPrice() {
    if (!userStore.isLogin || !userStore.userInfo) {
        router.push({
            name: 'login',
        });
        return false;
    }
    router.push({
        name: 'upload_paper',
    });
}

const nickname = computed(() => {
    if (isValidPhoneNumber(userStore.userInfo.username)) {
        return userStore.userInfo.username
    }
    return userStore.userInfo.nickname
});
</script>
<script>
export default {
    name: 'SiteHeader',
    methods: {}
}
</script>
<template>
    <header class="header">
        <div class="container flex-justify header-content">
            <a href="/" class="logo"><img src="@/assets/logo@2x.png" alt=""></a>
            <div class="header-right">
                <a class="link header-qr-code"><img src="@/assets/images/home/icon_erwm@2x.png" alt="">
                    <span>公众号</span>
                    <img src="@/assets/images/wx_qrcode.png" alt="" class="qr-code">
                </a>
                <a class="link"><img src="@/assets/images/home/icon_dianhua@2x.png" alt="">13422102293</a>
                <template v-if="userStore.isLogin">
                    <a class="link username is-login">
                        <span>{{ nickname }}</span>
                        <i></i>
                        <span class="user-info">
                            <span class="btn" @click="toUserCenter">个人中心</span>
                            <span class="btn" @click="toModifyPassword">修改密码</span>
                            <span class="btn" @click="doLogout">退出登录</span>
                        </span>

                    </a>
                </template>
                <template v-if="!userStore.isLogin">
                    <a class="link">
                        <router-link to="/login">登录</router-link>
                        /
                        <router-link to="/register">注册</router-link>
                    </a>
                </template>

                <a @click="toInQueryPrice" class="btn-primary">立即询价</a>
            </div>
        </div>
    </header>
    <div class="app-menu">
        <ul class="container menu">
            <li class="menu-item">
                <router-link to="/">首页</router-link>
            </li>
            <li class="menu-item has-children">
                <a>英文润色<i></i></a>
                <ul class="sub-menu">
                    <li class="sub-menu-item" >
                        <router-link to="/english_editing/english_editing_st">标准润色</router-link>
                    </li>
                    <li class="sub-menu-item" >
                        <router-link to="/english_editing/english_editing_hq">优质润色</router-link>
                    </li>
                    <li class="sub-menu-item" >
                        <router-link to="/english_editing/english_editing_sc">科学润色</router-link>
                    </li>
                    <li class="sub-menu-item" >
                        <router-link to="/english_editing/english_editing_plan">服务方案比较</router-link>
                    </li>
                </ul>
            </li>
            <li class="menu-item has-children">
                <a>学术翻译<i></i></a>
                <ul class="sub-menu">
                    <li class="sub-menu-item" >
                        <router-link to="/translating/translating_ai">人工智能翻译</router-link>
                    </li>
                    <li class="sub-menu-item" >
                        <router-link to="/translating/translating_sci">SCI论文翻译</router-link>
                    </li>
                    <li class="sub-menu-item" >
                        <router-link to="/translating/translating_scip">翻译服务方案升级版</router-link>
                    </li>
                    <li class="sub-menu-item" >
                        <router-link to="/translating/translating_plan">服务方案比较</router-link>
                    </li>
                </ul>
            </li>
            <li class="menu-item">
                <router-link to="/edit_repeat">降重改写</router-link>
            </li>
<!--            <li class="menu-item">-->
<!--                <router-link to="/english_repeat">论文查重</router-link>-->
<!--            </li>-->
            <li class="menu-item">
                <router-link to="/price">服务价格</router-link>
            </li>
            <li class="menu-item">
                <router-link to="/articles">学术资源</router-link>
            </li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
.header {
    position: relative;
    z-index: 10;

    .header-content {

        .logo {
            img {
                height: 30px;
                display: block;
            }
        }

        .header-right {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .header-qr-code {
                position: relative;

                &:hover {
                    .qr-code {
                        display: block;
                    }
                }

                .qr-code {
                    display: none;
                    top: 78px;
                    left: 50%;
                    width: 180px;
                    position: absolute;
                    margin-left: -90px;
                    height: auto;
                    z-index: 9;
                    border: solid 1px #eee;
                    box-shadow: 0 3px 7px rgba(0, 0, 0, .15);
                }
            }

            .link {
                display: flex;
                align-items: center;
                height: 80px;
                user-select: none;
                cursor: pointer;

                img {
                    height: 22px;
                    margin-right: 10px;
                }

                & + .link {
                    margin-left: 38px;
                }

            }

            .username {
                position: relative;
                display: flex;
                align-items: center;
                padding-right: 24px;
                user-select: none;
                cursor: pointer;

                i {
                    position: absolute;
                    top: 50%;
                    margin-top: -2px;
                    right: 5px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-color: #333 transparent transparent transparent;
                    border-width: 5px;
                }

                &:hover {
                    .user-info {
                        top: 80%;
                    }

                    i {
                        border-color: transparent transparent #333 transparent;
                        margin-top: -6px;
                    }
                }

                .user-info {
                    position: absolute;
                    background: #fff;
                    box-shadow: 0 3px 7px rgba(0, 0, 0, .25);
                    top: -200%;
                    left: 50%;
                    margin-left: -60px;
                    z-index: 99;

                    .btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        user-select: none;
                        cursor: pointer;
                        width: 120px;
                        padding: 10px 0;
                        border-radius: 8px;
                        text-decoration: none;
                        color: #333;

                        &:hover {
                            color: #0066D9;
                        }
                    }
                }
            }

            .btn-primary {
                margin-left: 30px;
            }
        }
    }
}

.link {
    a {
        color: inherit;
        text-decoration: none;
    }
}

.app-menu {
    background-color: #0066D9;
    position: relative;
    z-index: 9;

    .menu {
        color: #fff;
        display: flex;
        align-items: center;


        .menu-item {
            position: relative;

            a {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: 140px;
                height: 50px;
                line-height: 50px;
                cursor: pointer;

                i {
                    border-style: solid;
                    border-color: #fff transparent transparent transparent;
                    border-width: 4px;
                    margin-left: 12px;
                }

                &:hover {
                    background-color: #0057BC;

                    i {
                        transform: translateY(-3px);
                        border-color: transparent transparent #fff transparent;
                    }
                }
            }

            &:hover {
                ul {
                    display: block;
                }
            }

            .sub-menu {
                position: absolute;
                z-index: 99;
                top: 100%;
                left: 0;
                display: none;
                background-color: #fff;
                box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.12);
                border-radius: 4px;

                &:after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    margin-left: -8px;
                    top: -16px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-color: transparent transparent #fff transparent;
                    border-width: 8px;

                }

                li {
                    &:first-child {
                        a {
                            border-top-left-radius: 5px;
                            border-top-right-radius: 5px;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom-left-radius: 5px;
                            border-bottom-right-radius: 5px;
                        }
                    }

                    a {
                        color: #000;
                        display: block;
                        width: 200px;
                        height: 50px;
                        line-height: 50px;
                        text-align: left;
                        text-indent: 33px;
                    }

                    &:hover {
                        a {
                            background-color: #E5F1FF;
                            color: #000;
                        }
                    }
                }

            }
        }

        a {
            color: #fff;
            text-decoration: none;

            &:hover {
                color: #fff;
            }
        }
    }
}
</style>
