<script>
export default {
    name: 'SiteFooter'
}
</script>
<template>
    <div class="site-footer-tips">
        <div class="container">
            <p><span>*</span>免责声明: 本网站提及的所有第三方名称或商标（任何形式的图形标识），其知识产权均属于相应的期刊或出版商。德微思与上述机构无从属关系，也未获得任何赞助与背书。所有商标引用均出于解释服务内容的考量，符合商标法规范。德微思不承诺使用本网站提供的相关服务可保证在上述机构发表。</p>
        </div>
    </div>
    <div class="site-footer">
        <div class="container">
            <div class="site-footer-item">
                <div class="site-footer-item-title">关于我们</div>
                <div class="site-footer-text">东莞市微思网络科技有限公司所属的Editwis（德微思）， <br>
                    是为非英语国家科研工作者提供<br>英文学术文章翻译、文章润色、文章指导与文章修改<br>等服务的专业科研服务品牌。
                    <br>
                    Editwis（德微思）创立于美丽的松山湖科技园，<br>公司的主创人员均有10多年的论文服务经验， <br>
                    携手顶尖的科学家及期刊编辑，<br>共同打造了一支专业性强、经验丰富、学科齐全<br>的精通中英双语和100%英语母语的<br>学术翻译及专业润色团队，<br>我们竭诚为您提供专业、准时、无忧、个性化的服务体验
                    ，助力您的 <br>
                    为我们的科研学者、高校、出版商和企业提供<br>英文润色、学术翻译和SCI/SSCI/EI期刊论文发表<br>指导和科研传播服务。<br>
                    我们已经为来自全球超过150个国家<br>的数万名作者提供服务，<br>翻译润色完成超过数万份稿件。
                </div>
            </div>
            <div class="site-footer-item site-footer-links">
                <div class="site-footer-item-title">关于我们</div>
                <div class="site-footer-text">
                    <ul>
                        <li>
                            <router-link to="/english_editing">英文润色</router-link>
                        </li>
                        <li>
                            <router-link to="/translating">学术翻译</router-link>
                        </li>
                        <li>
                            <router-link to="/edit_repeat">语言改写降重</router-link>
                        </li>
<!--                        <li>-->
<!--                            <router-link to="/english_repeat">论文查重</router-link>-->
<!--                        </li>-->
                        <li>
                            <router-link to="/articles">学术资源</router-link>
                        </li>
                        <li>
                            <router-link to="/price">服务价格</router-link>
                        </li>
                        <li>
                            <router-link to="/payment">付款及优惠</router-link>
                        </li>
                        <li>
                            <router-link to="/about/project">润色案例</router-link>
                        </li>
                        <li>
                            <router-link to="/about">公司简介</router-link>
                        </li>
                        <li>
                            <router-link to="/about/team">团队简介</router-link>
                        </li>
                        <li>
                            <router-link to="/about/contact">联系我们</router-link>
                        </li>
                        <li>
                            <router-link to="/help">帮助</router-link>
                        </li>

                        <!--                        <li class="menu-item has-children">-->
                        <!--                            <a>服务类型<i></i></a>-->
                        <!--                            <ul class="sub-menu">-->
                        <!--                                <li class="sub-menu-item" v-for="(item,index) in servicesIntroNav" :key="index">-->
                        <!--                                    <router-link :to="`${item.path}`">{{ item.name }}</router-link>-->
                        <!--                                </li>-->
                        <!--                            </ul>-->
                        <!--                        </li>-->
                        <!--                        <li class="menu-item has-children">-->
                        <!--                            <a>关于我们<i></i></a>-->
                        <!--                            <ul class="sub-menu">-->
                        <!--                                <li v-for="(item,index) in aboutNav" :key="index" class="sub-menu-item">-->
                        <!--                                    <router-link :to="`${item.path}`">{{ item.name }}</router-link>-->
                        <!--                                </li>-->
                        <!--                            </ul>-->
                        <!--                        </li>-->
                        <!--                        <li class="menu-item">-->
                        <!--                            <router-link to="/payment">付款及优惠</router-link>-->
                        <!--                        </li>-->
                        <!--                        <li class="menu-item">-->
                        <!--                            <router-link to="/price">服务价格</router-link>-->
                        <!--                        </li>-->
                        <!--                        <li class="menu-item">-->
                        <!--                            <router-link to="/about/contact">联系我们</router-link>-->
                        <!--                        </li>-->
                        <!--                        <li class="menu-item">-->
                        <!--                            <router-link to="/help">帮助</router-link>-->
                        <!--                        </li>-->
                    </ul>
                </div>
            </div>
            <div class="site-footer-item">
                <div class="site-footer-item-title">联系我们</div>
                <div class="site-footer-text">
                    咨询学术顾问： <br>
                    13422102293（周一至周日，9:00-23:00） <br><br>
                    建议反馈/客户投诉： <br>
                    13422102293 <br><br>
                    市场/推广合作： <br>
                    134 2210 2293
                </div>
            </div>
        </div>
        <div class="container">
            <div class="site-footer-info">
                <p>© 2002—2022 微思网络科技有限公司版权所有。 <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"><img src="../../assets/images/beian.png" alt="">粤ICP备</a> <a href="https://beian.miit.gov.cn/" target="_blank" class="copyright-item">2021145137号</a></p>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.site-footer-tips {
    margin-top: 60px;
    background: #fafafa;
    padding: 30px 0;
    line-height: 2;
    font-size: 13px;
    color: #666;

    span {
        color: red;
    }
}

.site-footer {
    background-color: #3A3A3A;
    color: #A4A4A4;
    padding: 40px 0;
    font-size: 14px;
}

.site-footer a,
.site-footer a:visited,
.site-footer a:active,
.site-footer a:link {
    color: inherit;
    text-decoration: none;
    position: relative;
}

.site-footer-item {
    display: inline-block;
    vertical-align: top;
}

.site-footer-item-title {
    color: #fff;
    font-size: 16px;
    height: 1em;
    line-height: 1;
    margin-bottom: 30px;
}

.site-footer-links {
    width: 300px;
    font-size: 0;
    margin-left: 70px;
}

.site-footer-links li {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 50%;
    font-size: 14px;
}


.site-footer-links li a {
    padding-left: 10px;
}

.site-footer-links li a:hover {
    color: #fff;
    text-decoration: underline;
}

.site-footer-links li a:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    background-color: #A4A4A4;
    border-radius: 6px;
    width: 6px;
    height: 6px;
    margin-top: -3px;
}

.site-footer-text {
    line-height: 22px;
}

.site-footer-info {
    text-align: center;
    margin-top: 80px;
    border-top: solid 1px #4A4A4A;
    padding-top: 40px;
}

.site-footer-info p img {
    vertical-align: middle;
    margin-bottom: 4px;
    margin-right: 4px;
}
</style>
<script setup>
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?c193cd779962174de2e4c163990f8b74";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();
</script>