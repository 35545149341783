import {post} from "@/common/request";
import {upload} from "@/common/upload";


/**
 * 获取稿件服务
 * @param token
 * @param pid
 * @returns {Promise<unknown>}
 */
export const paperServices = async ({token, pid}) => {
    return await post(`/api/v1/service/show`, {token, service_id: pid});
};
export const paperServicePackages = async ({token, service_pid}) => {
    return await post(`/api/v1/service/package`, {token, service_pid})
}
export const paperDemand = async (token, service_pid) => {
    const data = {token};
    if (service_pid) {
        data.service_pid = service_pid;
    }
    return await post(`/api/v1/manuscript/demand`, data);
};

export const addPaper = async (data) => {
    return await post(`/api/v1/manuscript/create`, data);
};

export const uploadPaperFile = (data, progressFn) => upload(`/api/v1/upload/file`, data, progressFn)

export const getPaperList = (data) => post('/api/v1/manuscript/show', data)

export const getPaperPrice = data => post('/api/v1/manuscript/calculatePrice', data)

export const getApiDomain = () => {
    return window.location.hostname === 'localhost' ? 'http://localhost:3148' : 'http://www.editwis.cn'
}
