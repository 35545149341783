<script setup>
import {defineProps} from 'vue';
defineProps({
    stars: {
        type: Number,
        default(){
            return 0;
        }
    }
});
</script>
<script>
export default {
    name: "UStar"
}
</script>
<template>
    <div class="star-list">
        <i v-for="starItem in stars" :key="`star-${starItem}`" :class="['star', `star-${starItem}`]"></i>
    </div>
</template>
<style lang="scss">
.star-list {
    display: flex;

    .star {
        display: block;
        width: 22px;
        height: 22px;
        background: url("../assets/images/about/star.png") center no-repeat;
        background-size: 100%;

        & + .star {
            margin-left: 6px;
        }
    }
}
</style>
