export function upload(url, data, progressFn, binary) {
    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.open('post', url);
        xhr.onreadystatechange = function () {
            let resText = xhr.responseText;
            if (xhr.readyState === 4 && xhr.status === 200) {
                try {
                    let res = JSON.parse(resText);
                    resolve(res);
                } catch (e) {
                    resolve(resText);
                }
                return;
            }
            if (xhr.readyState === 4 && xhr.status === 500) {
                reject(resText)
            }
        };
        if (xhr.upload) {
            xhr.upload.onprogress = function (e) {
                let progressPercentage = Math.floor((e.loaded / e.total) * 100);
                progressFn && progressFn(progressPercentage)
            };
        }
        xhr.ontimeout = xhr.onerror = xhr.onabort = function (e) {
            reject({
                status: 500,
                msg: {timeout: '请求超时', error: '无法连接网络', abort: '请求中止'}[e.type],
                type: e.type
            });
        };
        if (binary) {
            xhr.overrideMimeType && xhr.overrideMimeType('application/octet-stream');
            let fr = new FileReader();
            fr.onload = function () {
                xhr.send(data);
                fr = fr.onload = null;
            };
            fr.readAsArrayBuffer(data.file);
        } else {
            xhr.send(data);
        }
    });
}

export function pickFile(accept) {
    return new Promise((resolve, reject) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = accept || 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.doc,.docx,.tex,.pdf,application/pdf';
        input.onchange = async function (e) {
            resolve && resolve(e.target.files);
            document.body.removeChild(input);
        }
        input.onerror = () => {
            reject && reject();
        }
        input.style.position = 'absolute';
        input.style.top = '-999px';
        input.style.left = '-999px';
        document.body.appendChild(input);
        input.click();
    })
}
