import {PageAboutMenus} from "@/common/config";

export const serviceIntroNav = [
    {
        path: '/english_editing',
        name: 'english_editing',
        meta: {
            title: '英文润色',
        },
        redirect: {
            name: 'english_editing_st'
        },
        children: [
            {
                path: 'english_editing_st',
                name: 'english_editing_st',
                title: '标准润色',
                componentName: 'EditEditingStandard',
                component: () => import('@/views/services/intro/EditEditingStandard.vue'),
                meta: {}
            },
            {
                path: 'english_editing_hq',
                name: 'english_editing_hq',
                title: '优质润色',
                componentName: 'EditEditingPremium',
                component: () => import('@/views/services/intro/EditEditingHighQuality.vue'),
                meta: {}
            },
            {
                path: 'english_editing_sc',
                name: 'english_editing_sc',
                title: '科学润色',
                componentName: 'EditEditingTopJournal',
                component: () => import('@/views/services/intro/EditEditingScience.vue'),
                meta: {}
            },
            {
                path: 'english_editing_plan',
                name: 'english_editing_plan',
                title: '服务方案比较',
                componentName: 'EditEditingPlan',
                component: () => import('@/views/services/intro/EditEditingPlan.vue'),
                meta: {}
            },
        ]
    },
    {
        path: '/translating',
        name: 'translating',
        meta: {
            title: '学术翻译',
        },
        redirect: {
            name: 'translating_ai'
        },
        children: [
            {
                path: 'translating_ai',
                name: 'translating_ai',
                title: '人工智能翻译',
                componentName: 'EditEditingCompare',
                component: () => import('@/views/services/intro/TranslatingAI.vue'),
                meta: {}
            },
            {
                path: 'translating_sci',
                name: 'translating_sci',
                title: 'SCI论文翻译',
                componentName: 'EditEditingCompare',
                component: () => import('@/views/services/intro/TranslatingSCI.vue'),
                meta: {}
            },
            {
                path: 'translating_scip',
                name: 'translating_scip',
                title: '翻译服务方案升级版',
                componentName: 'EditEditingCompare',
                component: () => import('@/views/services/intro/TranslatingSCIPlus.vue'),
                meta: {}
            },
            {
                path: 'translating_plan',
                name: 'translating_plan',
                title: '服务方案比较',
                componentName: 'EditEditingCompare',
                component: () => import('@/views/services/intro/TranslatingPlan.vue'),
                meta: {}
            },
        ]
    },
]

export const basicRoutes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/HomeView.vue'),
        meta: {
            title: "Editwis德微思|SCI英文母语润色公司|专业翻译服务|英语重复率改写机构|期刊投稿查重服务|www.editwis.cn"
        }
    },
    {
        path: '/help',
        name: 'help',
        component: () => import('@/views/HelpView.vue'),
        meta: {
            title: "帮助"
        }
    },
    {
        path: '/pay_help',
        name: 'pay_help',
        component: () => import('@/views/HelpPayView.vue'),
        meta: {
            title: "支付及发票"
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/LoginView.vue'),
        meta: {
            title: "登录"
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/LoginView.vue'),
        meta: {
            title: "注册"
        }
    },
    {
        path: '/forget_password',
        name: 'forget_password',
        component: () => import('@/views/LoginView.vue'),
        meta: {
            title: "忘记密码"
        }
    },
    {
        path: '/modify_password',
        name: 'modify_password',
        component: () => import('@/views/LoginView.vue'),
        meta: {
            title: "修改密码",
            needAuth: true,
        }
    },
    {
        path: '/upload_paper',
        name: 'upload_paper',
        component: () => import('@/views/UploadPaper.vue'),
        meta: {
            title: " 个人中心-SCI英文论文润色、SCI论文翻译、学术修改降重、论文润色翻译服务公司Editsprings"
        }
    },
    {
        path: '/price',
        name: 'price',
        component: () => import('@/views/price/PriceView.vue'),
        meta: {
            title: "服务价格"
        },
    },
    {
        path: '/payment',
        name: 'payment',
        component: () => import('@/views/HelpPayView.vue'),
        meta: {
            title: "付款及优惠"
        },
    },
    ...serviceIntroNav,
    {
        path: '/paper_repeat',
        name: 'paper_repeat',
        component: () => import('@/views/services/PaperRepeat.vue'),
        meta: {
            title: '语言改写降重',
        }
    },
    {
        path: '/english_repeat',
        name: 'english_repeat',
        component: () => import('@/views/services/EnglishRepeat.vue'),
        meta: {
            title: '英文查重',
        }
    },
    {
        path: '/edit_repeat',
        name: 'edit_repeat',
        component: () => import('@/views/services/EditRepeat.vue'),
        meta: {
            title: '降重查重',
        }
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/AboutView.vue'),
        redirect: '/about/company',
        children: PageAboutMenus.map(item => {
            return Object.assign(item, {
                path: item.name,
                component: () => import(`@/views/about/${item.componentName}`),
            })
        })
    },
    {
        path: '/article/:id',
        name: 'article',
        component: () => import('@/views/Article.vue'),
        meta: {
            title: "文章"
        }
    },
    {
        path: '/articles',
        name: 'articles',
        component: () => import('@/views/ArticleList.vue'),
        meta: {
            title: "文章"
        }
    },
    {
        path: '/paper_repeat_1',
        name: 'paper_repeat_1',
        component: () => import('@/views/PaperRepeat.vue'),
        meta: {
            title: "论文查重"
        }
    }
]
