import AboutBannerCompany from '../assets/images/about/pic_about_company.png'
import AboutBannerComment from '../assets/images/about/pic_about_comment.png'
import AboutBannerTeam from '../assets/images/about/pic_about_team.png'
import AboutBannerProject from '../assets/images/about/pic_about_project.png'
import AboutBannerContact from '../assets/images/about/pic_about_contact.png'
export const PageAboutMenus =  [
    {
        name: 'company',
        title: '公司简介',
        componentName: 'AboutCompany',
        meta:{
            banner: AboutBannerCompany
        }
    },
    {
        name: 'team',
        title: '团队介绍',
        componentName: 'AboutTeam',
        meta:{
            banner: AboutBannerTeam
        }
    },
    {
        name: 'comment',
        title: '客户评价',
        componentName: 'AboutUserComment',
        meta:{
            banner: AboutBannerComment
        }
    },
    {
        name: 'project',
        title: '润色案例',
        componentName: 'AboutProject',
        meta:{
            banner: AboutBannerProject
        }
    },
    {
        name: 'contact',
        title: '联系我们',
        componentName: 'AboutContact',
        meta:{
            banner: AboutBannerContact
        }
    }
];

// 百度地图ak
export const BAIDU_MAP_AK = 'CqavTYKjpeUfVFk3VvrSxai022t2r7Gg';
